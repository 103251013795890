import {BaseLoginProvider} from '../models/login-provider';
import {SocialUserDataCredentials} from '../models/user-verification';

declare let gapi: any;

interface GoogleRequestOptions {
  readonly scope?: string;
}

interface GoogleError {
  readonly error: string;
  readonly details: string;
}

const defaultGoogleRequestOptions: Required<GoogleRequestOptions> = {
  scope: 'email',
};

export class GoogleAuthProvider extends BaseLoginProvider {

  private readonly requestOptions: GoogleRequestOptions;

  protected auth2: any;

  constructor(
    private readonly clientId: string,
    options?: GoogleRequestOptions,
  ) {
    super();
    this.requestOptions = {
      ...defaultGoogleRequestOptions,
      ...options,
    };
  }

  initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript(
          'GOOGLE',
          'https://apis.google.com/js/platform.js',
          () => {
            gapi.load('auth2', () => {
              this.auth2 = gapi.auth2.init({
                ...this.requestOptions,
                clientId: this.clientId,
              });

              this.auth2
                .then(() => {
                  this.initState$.next(true);
                  resolve();
                })
                .catch((err: GoogleError) => {
                  reject(err);
                });
            });
          },
        );
      } catch (error) {
        reject(error);
      }
    });
  }

  signIn(signInOptions: any): Promise<SocialUserDataCredentials> {
    return new Promise<SocialUserDataCredentials>((resolve, reject) => {
      this.auth2.signIn(signInOptions).then((response: any) => {
        const authResponse = this.auth2.currentUser.get().getAuthResponse(true);
        resolve({accessToken: authResponse.access_token});
      }).catch(error => reject(error));
    });
  }

  signOut(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.auth2.signOut().then((error: any) => {
          if (error) {
            reject(error);
            return;
          }
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  }
}
